<template>
  <div class="rooBox">
    <div class="contentBox boxStyle">
      <div class="operateOut">
        <p>{{ $t("taskList.dockTaskList") }}</p>
        <div>
          <el-button size="small" type="primary" @click="createTask()">{{
            $t("taskList.newTask")
          }}</el-button>

          <el-button
            size="small"
            @click="taskCopy()"
            :disabled="this.multipleSelection.length != 1"
            >{{ $t("taskList.taskCopy") }}
          </el-button>

          <!-- <el-popconfirm
            :confirm-button-text="$t('button.confirm')"
            @confirm="batchDelete()"
            :cancel-button-text="$t('button.cancel')"
            icon="el-icon-info"
            icon-color="red"
            :title="$t('taskList.deleteSelected')"
            style="margin-left: 10px"
          >
            <el-button size="small" slot="reference">{{
              $t("taskList.batchDelete")
            }}</el-button>
          </el-popconfirm> -->
        </div>
      </div>
      <div class="tableBox">
        <el-table
          :data="tableData"
          stripe
          header-cell-class-name="tableThStyle"
          ref="form"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" min-width="55"></el-table-column>
          <el-table-column
            type="index"
            :index="indexMethod"
            min-width="50"
            :label="$t('common.serialNum')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="taskName"
            min-width="100"
            :label="$t('taskList.taskName')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="integrationMode"
            min-width="120"
            :label="$t('taskList.integrationMode')"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{
                scope.row.integrationMode == "client.database"
                  ? $t("integrationModesShow.clientDatabase")
                  : ""
              }}
              {{
                scope.row.integrationMode == "client.ftp"
                  ? $t("integrationModesShow.clientFtp")
                  : ""
              }}
              {{
                scope.row.integrationMode == "server.ftp"
                  ? $t("integrationModesShow.serverFtp")
                  : ""
              }}
              {{
                scope.row.integrationMode == "client.sftp"
                  ? $t("integrationModesShow.clientSftp")
                  : ""
              }}
              {{
                scope.row.integrationMode == "server.sftp"
                  ? $t("integrationModesShow.serverSftp")
                  : ""
              }}
              {{
                scope.row.integrationMode == "client.local"
                  ? $t("integrationModesShow.clientLocal")
                  : ""
              }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            min-width="100"
            :label="$t('taskList.dataSource')"
          >
            <template slot-scope="scope">
              <el-popover
                trigger="hover"
                placement="top"
                v-if="scope.row.integrationMode == 'client.database'"
              >
                <p>Url: {{ scope.row.datasource.databaseUrl }}</p>
                <p>Username: {{ scope.row.datasource.databaseUsername }}</p>
                <p>Password: {{ scope.row.datasource.databasePassword }}</p>
                <div slot="reference" class="name-wrapper">
                  <el-link type="primary" size="medium">{{
                    scope.row.datasource.databaseType
                  }}</el-link>
                </div>
              </el-popover>
              <el-popover
                trigger="hover"
                placement="top"
                v-if="scope.row.integrationMode != 'client.database'"
              >
                <div v-if="scope.row.integrationMode == 'client.local'">
                  <p>localDir: {{ scope.row.datasource.localDir }}</p>
                  <p>ColumnNum: {{ scope.row.datasource.fileColumnNum }}</p>
                </div>

                <div v-if="scope.row.integrationMode == 'client.ftp'">
                  <p>FTP HOST: {{ scope.row.datasource.ftpClientHost }}</p>
                  <p>FTP PORT: {{ scope.row.datasource.ftpClientPort }}</p>
                  <p>FTP USER: {{ scope.row.datasource.ftpClientUsername }}</p>
                  <p>
                    FTP PASSWORD: {{ scope.row.datasource.ftpClientPassword }}
                  </p>
                  <p>FTP FILEPATH: {{ scope.row.datasource.ftpClientPath }}</p>
                </div>

                <div v-if="scope.row.integrationMode == 'client.sftp'">
                  <p>SFTP HOST: {{ scope.row.datasource.sftpClientHost }}</p>
                  <p>SFTP PORT: {{ scope.row.datasource.sftpClientPort }}</p>
                  <p>
                    SFTP USER: {{ scope.row.datasource.sftpClientUsername }}
                  </p>
                  <p>
                    SFTP PASSWORD: {{ scope.row.datasource.sftpClientPassword }}
                  </p>
                  <p>
                    SFTP FILEPATH: {{ scope.row.datasource.sftpClientPath }}
                  </p>
                </div>
                <div slot="reference" class="name-wrapper">
                  <el-link type="primary" size="medium">{{
                    scope.row.integrationMode
                  }}</el-link>
                </div>
              </el-popover>
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            prop="executeCycle"
            min-width="100"
            :label="$t('taskList.taskCycle')"
          >
            <template slot-scope="scope">
              {{ scope.row.dayOrCycle == "day" ? $t("taskList.everyDay") : "" }}
              {{ scope.row.executeCycle }}
              {{ scope.row.dayOrCycle == "cycle" ? $t("taskList.min") : "" }}
            </template>
          </el-table-column>

          <!-- <el-table-column
            align="center"
            prop="remark"
            min-width="120"
            :label="$t('taskList.updateTime')"
            show-overflow-tooltip
          ></el-table-column> -->
          <el-table-column
            align="center"
            fixed="right"
            prop="integrationStatus"
            min-width="80"
            :label="$t('taskList.taskStatus')"
          >
            <template slot-scope="scope">
              <el-tag
                :type="
                  scope.row.integrationStatus == false ? 'danger' : 'success'
                "
                effect="dark"
              >
                {{
                  scope.row.integrationStatus == false
                    ? $t("taskList.off")
                    : $t("taskList.on")
                }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('common.operate')"
            fixed="right"
            min-width="100px"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                :class="
                  scope.row.integrationStatus == false
                    ? 'blueTextBtn'
                    : 'redTextBtn'
                "
                @click="
                  startOrStop(scope.row.integrationStatus, scope.row.taskName)
                "
                >{{
                  scope.row.integrationStatus == false
                    ? $t("taskList.start")
                    : $t("taskList.stop")
                }}</el-button
              >
              <el-button
                type="text"
                class="blueTextBtn"
                @click="edit(scope.row)"
                >{{ $t("button.edit") }}</el-button
              >
              <el-popconfirm
                :confirm-button-text="$t('button.confirm')"
                @confirm="deleteAppBtn(scope.$index, scope.row)"
                :cancel-button-text="$t('button.cancel')"
                icon="el-icon-info"
                icon-color="red"
                :title="$t('taskList.deleteSelected')"
                style="margin-left: 10px"
              >
                <el-button type="text" class="redTextBtn" slot="reference">{{
                  $t("button.delete")
                }}</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="paginationOut">
        <el-pagination
          background
          layout="prev, pager, next,jumper,sizes,total"
          popper-class="pageSizeSelect"
          :page-sizes="pagination.pageSizeOption"
          :page-size.sync="pagination.pageSize"
          :total="pagination.total"
          :current-page.sync="pagination.currentPage"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>

      <!-- <div>
        <el-form ref="" visiable="false" :model=""></el-form>
      </div> -->
    </div>
  </div>
</template>
<script>
import mixin from "@/mixins/mixins";
import i18n from "../../i18n";

export default {
  mixins: [mixin],
  name: "taskList",
  data() {
    return {
      multipleSelection: [],
      task: {},
      tableData: [],
      pagination: {
        total: null,
        pageSize: 5,
        currentPage: 1,
        pageSizeOption: [5, 10, 20],
      },
      taskList: [],
    };
  },
  created() {
    this.initTaskList();
  },
  methods: {
    handleSizeChange: function (pageSize) {
      this.pagination.pageSize = pageSize;
      this.handleCurrentChange(this.pagination.currentPage);
    },
    handleCurrentChange: function (currentPage) {
      this.pagination.currentPage = currentPage;
      this.currentChangePage(currentPage);
    },
    currentChangePage(currentPage) {
      let from = (currentPage - 1) * this.pagination.pageSize;
      let to = currentPage * this.pagination.pageSize;
      this.tableData = this.taskList.slice(from, to);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    indexMethod(index) {
      return (
        this.pagination.pageSize * (this.pagination.currentPage - 1) + index + 1
      );
    },
    initTaskList() {
      return new Promise((resolve, reject) => {
        this.$httpList
          .getTaskList()
          .then((res) => {
            this.taskList = res.data.task;
            this.tableData = this.taskList.slice(
              (this.pagination.currentPage - 1) * this.pagination.pageSize,
              this.pagination.currentPage * this.pagination.pageSize
            );
            this.pagination.total = res.data.task.length;
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    createTask() {
      this.$router.push({
        name: "taskCreate",
      });
    },
    taskCopy() {
      var copyTask = this.$refs.form.selection[0];
      var myDate = new Date();

      copyTask.taskName =
        copyTask.taskName +
        "_" +
        myDate.getFullYear() +
        myDate.getMonth() +
        myDate.getDay() +
        myDate.getHours() +
        myDate.getMinutes() +
        myDate.getSeconds();
      return new Promise((resolve, reject) => {
        this.$httpList
          .createOrUpdateTask(copyTask, "")
          .then((res) => {
            if (res.resultCode == 1001) {
              this.$notify({
                message: i18n.t("message.createTaskSuccess"),
                type: "success",
              });
              this.initTaskList();
            } else {
              this.$notify({
                message: i18n.t("message.createTaskFailed"),
                type: "error",
              });
            }
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    batchDelete() {
      this.$refs.form.selection.forEach((element) => {
        return new Promise((resolve, reject) => {
          this.$httpList
            .deleteTask(element)
            .then((res) => {
              resolve(res);
              this.initTaskList();
            })
            .catch((err) => {
              reject(err);
            });
        });
      });
    },
    deleteAppBtn(index, row) {
      return new Promise((resolve, reject) => {
        this.$httpList
          .deleteTask(row)
          .then((res) => {
            this.$notify.success(res);
            resolve(res);
            this.initTaskList();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    startOrStop(statu, taskName) {
      if (statu == false) {
        return new Promise((resolve, reject) => {
          this.$httpList
            .startTask(taskName)
            .then((res) => {
              if (res.resultCode == 1001) {
                this.$notify.success(i18n.t("message.startTaskSuccess"));
                this.initTaskList();
              } else {
                this.$notify.danger(i18n.t("message.startTaskFaild"));
              }
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        });
      } else {
        return new Promise((resolve, reject) => {
          this.$httpList
            .stopTask(taskName)
            .then((res) => {
              if (res.resultCode == 1001) {
                this.$notify.success(i18n.t("message.closeTaskSuccess"));
                this.initTaskList();
              } else {
                this.$notify.danger(i18n.t("message.closeTaskFaild"));
              }
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        });
      }
    },
    edit(thisTask) {
      localStorage.setItem("task", JSON.stringify({ task: thisTask }));
      this.$router.push({
        name: "taskCreate",
        // query: { params:  },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.redPoint {
  background-color: red;
}
.greenPoint {
  background-color: green;
}
</style>
